%heading {
  margin: 0;
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $font-weight-bold;
}

%h1 {
  @extend %heading;

  font-size: $font-size-heading-1-mobile;
  line-height: $font-line-height-heading-1-mobile;

  @include media-breakpoint-up(md) {
    font-size: $font-size-heading-1;
    line-height: $font-line-height-heading-1;
  }
}

%h2 {
  @extend %heading;

  font-size: $font-size-heading-2-mobile;
  line-height: $font-line-height-heading-2-mobile;

  @include media-breakpoint-up(md) {
    font-size: $font-size-heading-2;
    line-height: $font-line-height-heading-2;
  };
}

%h3 {
  @extend %heading;

  font-size: $font-size-heading-3-mobile;
  line-height: $font-line-height-heading-3-mobile;

  @include media-breakpoint-up(md) {
    font-size: $font-size-heading-3;
    line-height: $font-line-height-heading-3;
  }
}

%h4 {
  @extend %heading;

  font-size: $font-size-heading-4-mobile;
  line-height: $font-line-height-heading-4-mobile;

  @include media-breakpoint-up(md) {
    font-size: $font-size-heading-4;
    line-height: $font-line-height-heading-4;
  }
}

%h5 {
  @extend %heading;

  font-size: $font-size-heading-5-mobile;
  line-height: $font-line-height-heading-5-mobile;

  @include media-breakpoint-up(md) {
    font-size: $font-size-heading-5;
    line-height: $font-line-height-heading-5;
  }
}

%h6 {
  @extend %heading;

  font-size: $font-size-heading-6-mobile;
  line-height: $font-line-height-heading-6-mobile;

  @include media-breakpoint-up(md) {
    font-size: $font-size-heading-6;
    line-height: $font-line-height-heading-6;
  }
}
