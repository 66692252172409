/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Colors
2.0 Contexts
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Colors
--------------------------------------------------------------*/

// General
$color-black: #000000;
$color-white: #FFFFFF;

// Project specific

// Primary colors
$color-primary: #FF5454;
$color-primary-400: mix($color-white, $color-primary, 22%);
$color-primary-500: $color-primary;
$color-primary-600: mix($color-black, $color-primary, 12%);

// Secondary colors
$color-blue-300: #292F3C;
$color-blue-400: #202737;
$color-blue-500: #1B2230;

// Neutral colors
$color-grey-100: #F6F8FC;
$color-grey-200: #E2E8F0;
$color-grey-300: #CBD4E1;
$color-grey-400: #94A3B8;
$color-grey-500: #64748B;
$color-grey-600: #435166;

/*--------------------------------------------------------------
2.0 Contexts
--------------------------------------------------------------*/

// General
$color-background: $color-grey-100;
$color-text: $color-blue-500;

// Error
$color-error: #E68A00;
$color-error-100: mix($color-white, $color-error, 62%);
$color-error-200: mix($color-white, $color-error, 52%);
$color-error-300: mix($color-white, $color-error, 42%);
$color-error-400: mix($color-white, $color-error, 22%);
$color-error-500: $color-error;
$color-error-600: mix($color-black, $color-error, 12%);
$color-error-700: mix($color-black, $color-error, 22%);
$color-error-800: mix($color-black, $color-error, 32%);
$color-error-900: mix($color-black, $color-error, 42%);

// Info
$color-info: #519DF7;
$color-info-100: mix($color-white, $color-info, 62%);
$color-info-200: mix($color-white, $color-info, 52%);
$color-info-300: mix($color-white, $color-info, 42%);
$color-info-400: mix($color-white, $color-info, 22%);
$color-info-500: $color-info;
$color-info-600: mix($color-black, $color-info, 12%);
$color-info-700: mix($color-black, $color-info, 22%);
$color-info-800: mix($color-black, $color-info, 32%);
$color-info-900: mix($color-black, $color-info, 42%);

// Success
$color-success: #67CA8C;
$color-success-2: #70DC98;
$color-success-100: mix($color-white, $color-success, 62%);
$color-success-200: mix($color-white, $color-success, 52%);
$color-success-300: mix($color-white, $color-success, 42%);
$color-success-400: mix($color-white, $color-success, 22%);
$color-success-500: $color-success;
$color-success-600: mix($color-black, $color-success-2, 12%);
$color-success-700: mix($color-black, $color-success-2, 22%);
$color-success-800: mix($color-black, $color-success, 32%);
$color-success-900: mix($color-black, $color-success, 42%);

// Warning
$color-warning: #FFCE14;
$color-warning-100: mix($color-white, $color-warning, 62%);
$color-warning-200: mix($color-white, $color-warning, 52%);
$color-warning-300: mix($color-white, $color-warning, 42%);
$color-warning-400: mix($color-white, $color-warning, 22%);
$color-warning-500: $color-warning;
$color-warning-600: mix($color-black, $color-warning, 12%);
$color-warning-700: mix($color-black, $color-warning, 22%);
$color-warning-800: mix($color-black, $color-warning, 32%);
$color-warning-900: mix($color-black, $color-warning, 42%);
