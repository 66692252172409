@import "src/styles/prepend"; .button {
  all: unset;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-medium;
  line-height: $font-line-height-medium;
  border-radius: $layout-unit * 2.5;
  padding: $layout-unit * 2 $layout-unit * 4;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out, outline .3s ease-in-out, color .3s ease-in-out;

  // .button:disabled:not(.button--loading),
  // .button--disabled:not(.button--loading)
  &:disabled:not(&--loading),
  &--disabled:not(&--loading) {
    opacity: 0.5;
  }

  // .button:not(:disabled):not(.button--disabled):hover
  &:not(:disabled):not(.button--disabled):hover {
    cursor: pointer;
  }

  // .button__icon
  &__icon {
    height: $layout-unit * 5;
    width: $layout-unit * 5;

    // .button__icon--big
    &--big {
      height: $layout-unit * 6;
      width: $layout-unit * 6;
    }

    // .button__icon--on-the-left
    &--on-the-left {
      margin-left: 0;
      margin-right: $layout-unit * 2;
    }

    // .button__icon--on-the-right
    &--on-the-right {
      margin-right: 0;
      margin-left: $layout-unit * 2;
    }

    // .button__icon--no-margin
    &--no-margin {
      margin-left: 0;
      margin-right: 0;
    }

    // .button__icon--no-margin-left
    &--no-margin-left {
      margin-left: 0;
    }

    // .button__icon--no-margin-right
    &--no-margin-right {
      margin-right: 0;
    }

    // .button__icon--small
    &--small {
      height: $layout-unit * 4;
      width: $layout-unit * 4;
    }
  }

  // .button__loader
  &__loader {
    margin: 0;
    height: $layout-unit * 5;
    width: $layout-unit * 5;

    // .button__loader--margin-left
    &--margin-left {
      margin-left: $layout-unit * 2;
    }

    // .button__loader--margin-right
    &--margin-right {
      margin-right: $layout-unit * 2;
    }
  }

  // .button__text
  &__text {
    // .button__text--thin
    &--thin {
      font-weight: $font-weight-thin;
    }

    // .button__text--extralight
    &--extralight {
      font-weight: $font-weight-extralight;
    }

    // .button__text--light
    &--light {
      font-weight: $font-weight-light;
    }

    // .button__text--regular
    &--regular {
      font-weight: $font-weight-regular;
    }

    // .button__text--medium
    &--medium {
      font-weight: $font-weight-medium;
    }

    // .button__text--semibold
    &--semibold {
      font-weight: $font-weight-semibold;
    }

    // .button__text--bold
    &--bold {
      font-weight: $font-weight-bold;
    }

    // .button__text--extrabold
    &--extrabold {
      font-weight: $font-weight-extrabold;
    }

    // .button__text--black
    &--black {
      font-weight: $font-weight-black;
    }
  }

  // .button__text-and-icon
  &__text-and-icon {
    display: flex;
    align-items: center;
  }

  // .button--content-left
  &--content-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  // .button--extra-small
  &--extra-small {
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    border-radius: $layout-unit;
    padding-left: $layout-unit * 2.5;
    padding-right: $layout-unit * 2.5;

    // .button--extra-small.button--no-text:not(.button--no-padding)
    &.button--no-text:not(.button--no-padding) {
      padding: $layout-unit * 1.5;

      // .button--extra-small.button--no-text:not(.button--no-padding) .button__icon
      & .button__icon {
        height: $layout-unit * 5;
        width: $layout-unit * 5;
      }
    }

    // .button--extra-small.button--no-pading
    &.button--no-padding {
      padding: 0;
    }
  }

   // .button--grab-cursor:not(:disabled):not(.button--disabled):hover
   &--grab-cursor:not(:disabled):not(.button--disabled):hover {
    cursor: grab;
   }

  // .button--is-a-link
  &--is-a-link {
    font-size: $font-size-small;
    line-height: $font-line-height-small;

    // .button--is-a-link:disabled,
    // .button--is-a-link.button--disabled
    &:disabled,
    &--is-a-link#{&}--disabled {
      pointer-events: none;
    }
  }

  // .button--no-background:disabled,
  // .button--no-background.button--disabled
  &--no-background:disabled:not(.button--no-padding),
  &--no-background#{&}--disabled:not(.button--no-padding) {
    background-color: rgba($color-blue-500, 0.08);
  }

  // .button--no-background:not(:disabled, .button--disabled):focus-visible
  &--no-background:not(:disabled, .button--disabled):focus-visible {
    outline-color: $color-blue-500;
  }

  // .button--no-background.button--no-padding
  &--no-background#{&}--no-padding {
    border-radius: $layout-unit;

    // .button--no-background.button--no-padding.focus-visible
    &:global(.focus-visible) {
      // .button.focus-visible.button--no-background.button--no-padding::after
      &::after {
        content: none;
      }
    }

    // .button--no-background.button--no-padding.button--underline-on-hover::after
    &.button--underline-on-hover::after {
      content: '';
      position: absolute;
      pointer-events: none;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    // .button--no-background.button--no-padding.button--underline-on-hover:hover::after
    &.button--underline-on-hover:hover::after {
      opacity: 1;
    }
  }

  // .button--no-background,
  // .button--no-background.button--google,
  // .button--no-background.button--grey,
  // .button--no-background.button--primary,
  // .button--no-background.button--secondary,
  // .button--outline
  &--no-background,
  &--no-background#{&}--google,
  &--no-background#{&}--grey,
  &--no-background#{&}--primary,
  &--no-background#{&}--secondary,
  &--outline {
    background-color: transparent;
  }

  // .button--no-padding
  &--no-padding {
    padding: 0;
  }

  // .button--no-text:not(.button--no-padding)
  &--no-text:not(.button--no-padding) {
    padding: $layout-unit * 2.5;
  }

  // .button--small
  &--small {
    font-size: $font-size-small;
    line-height: $font-line-height-small;
    border-radius: $layout-unit * 2;
    padding-bottom: $layout-unit * 1.75;
    padding-top: $layout-unit * 1.75;

    // .button--small.button--no-padding
    &.button--no-padding {
      padding: 0;
    }
  }

  // --------------- Theme Styles ---------------

  // .button--google
  &--google {
    color: $color-blue-500;
    font-size: $font-size-small;
    line-height: $font-line-height-small;
    border-radius: $layout-unit * 3;
    padding-top: $layout-unit * 3;
    padding-bottom: $layout-unit * 3;
    box-shadow: inset 0 0 0 1px #EEF2F7;
    background-color: $color-white;

    // .button--google:active:not(:disabled),
    // .button--google:active:not(.button--disabled)
    &:active:not(:disabled),
    &:active:not(.button--disabled){
      background-color: rgba($color-grey-200, 0.3);
    }

    // Necessary to ensure that we have mouse hover
    @media (hover: hover) {
      // .button--google:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
      &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
        background-color: rgba($color-grey-200, 0.3);
      }
    }

    // .button--google.focus-visible
    &:global(.focus-visible) {
      box-shadow: inset 0 0 0 2px $color-grey-200;
    }
  }

  // .button--grey
  &--grey {
    color: $color-blue-500;
    border-radius: $layout-unit * 2.5;
    box-shadow: inset 0 0 0 1px $color-grey-200;
    background-color: $color-grey-100;

    // .button--grey:active:not(:disabled),
    // .button--grey:active:not(.button--disabled)
    &:active:not(:disabled),
    &:active:not(.button--disabled) {
      background-color: $color-grey-200;
    }

    // Necessary to ensure that we have mouse hover
    @media (hover: hover) {
      // .button--grey:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
      &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
        background-color: $color-grey-200;
      }
    }

    // .button--grey.focus-visible
    &:global(.focus-visible) {
      outline-color: $color-blue-500;
    }
  }

  // .button--primary
  &--primary {
    color: $color-white;

   
    // .button--primary:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):active
    &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):active {
      background-color: $color-primary-600;
    }

    // Necessary to ensure that we have mouse hover
    @media (hover: hover) {
      // .button--primary:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
      &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
        background-color: $color-primary-600;
      }
    }

    // .button--primary.focus-visible
    &:global(.focus-visible) {
      outline-color: #da4a4b;
      outline-color: var(--restaurant-color);
    }

    // .button--primary:not(.button--no-background):not(.button--outline)
    &:not(.button--no-background):not(.button--outline) {
      background-color: $color-primary-500;
      background-color: var(--restaurant-color);
    }

    // .button--primary.button--no-background
    &.button--no-background {
      color: $color-primary-500;
      color: var(--restaurant-color);
      outline-offset: 0;

      // .button--primary.button--no-background:not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):active
      &:not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):active {
        color: $color-white;
        background-color: $color-primary-600;
      }

      // Necessary to ensure that we have mouse hover
      @media (hover: hover) {
        // .button--primary.button--no-background:not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):hover
        &:not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):hover {
          cursor: pointer;
          color: $color-white;
          background-color: $color-primary-600;
        }
      }

      // .button--primary.button--no-background.focus-visible
      &:global(.focus-visible) {
        outline-color: $color-primary-500;
        outline-color: var(--restaurant-color);
      }
    }

    // .button--primary.button--no-background.button--no-padding
    &.button--no-background.button--no-padding {
      color: $color-primary-500;
      color: var(--restaurant-color);
      outline-offset: 2px;

      // .button--primary.button--no-background.button--no-padding:active:not(:disabled),
      // .button--primary.button--no-background.button--no-padding:active:not(.button--disabled)
      &:active:not(:disabled),
      &:active:not(.button--disabled) {
        color: $color-primary-600;
        color: var(--restaurant-color);
        background-color: transparent;
      }

      // Necessary to ensure that we have mouse hover
      @media (hover: hover) {
        // .button--primary.button--no-background.button--no-padding:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
        &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
          color: $color-primary-600;
          background-color: transparent;
        }
      }

      // .button--primary.button--no-background.button--no-padding.button--underline-on-hover::after
      &.button--underline-on-hover::after {
        background-color: $color-primary-500;
        background-color: var(--restaurant-color);
      }
    }

    // .button--primary.button--outline
    &.button--outline {
      color: $color-primary-500;
      color: var(--restaurant-color);
      box-shadow: inset 0 0 0 1px rgba($color-primary-500, 0.4);
      box-shadow: inset 0 0 0 1px rgba(var(--restaurant-color), 0.4);

      // .button--primary.button--outline:active:not(:disabled),
      // .button--primary.button--outline:active:not(.button--disabled)
      &:active:not(:disabled),
      &:active:not(.button--disabled) {
        box-shadow: inset 0 0 0 1px $color-primary-500;
        box-shadow: inset 0 0 0 1px var(--restaurant-color);
        background-color: rgba($color-primary-500, 0.04);
        background-color: rgba(var(--restaurant-color), 0.04);
      }

      // Necessary to ensure that we have mouse hover
      @media (hover: hover) {
        // .button--primary.button--outline:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
        &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
          box-shadow: inset 0 0 0 1px $color-primary-500;
          background-color: rgba($color-primary-500, 0.04);
        }
      }

      // .button--primary.button--outline.focus-visible
      &:global(.focus-visible) {
        outline-color: $color-primary-500;
        outline-color: var(--restaurant-color);
      }
    }
  }

  // .button--secondary
  &--secondary {
    color: $color-white;

    // .button--secondary:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):active
    &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):active {
      background-color: $color-blue-400;
    }

    // Necessary to ensure that we have mouse hover
    @media (hover: hover) {
      // .button--secondary:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
      &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
        background-color: $color-blue-400;
      }
    }

    // .button--secondary.focus-visible
    &:global(.focus-visible) {
      outline-color: $color-blue-300;
    }

    // .button--secondary:not(.button--no-background):not(.button--outline)
    &:not(.button--no-background):not(.button--outline) {
      background-color: $color-blue-500;
    }

    // .button--secondary.button--no-background
    &.button--no-background {
      color: $color-blue-500;
      outline-offset: 0;

      // .button--secondary.button--no-background:not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):active
      &:not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):active {
        color: $color-white;
        background-color: $color-blue-400;
      }

      // Necessary to ensure that we have mouse hover
      @media (hover: hover) {
        // .button--secondary.button--no-background:not(:disabled):not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):hover
        &:not(:disabled):not(.button--disabled):not(.button--outline):not(.button--no-padding):hover {
          cursor: pointer;
          color: $color-white;
          background-color: $color-blue-400;
        }
      }

      // .button--secondary.button--no-background.focus-visible
      &:global(.focus-visible) {
        outline-color: $color-blue-500;
      }
    }

    // .button--secondary.button--no-background.button--no-padding
    &.button--no-background.button--no-padding {
      color: $color-blue-500;
      outline-offset: 2px;

      // .button--secondary.button--no-background.button--no-padding:active:not(:disabled),
      // .button--secondary.button--no-background.button--no-padding:active:not(.button--disabled)
      &:active:not(:disabled),
      &:active:not(.button--disabled) {
        color: $color-black;
        background-color: transparent;
      }

      // Necessary to ensure that we have mouse hover
      @media (hover: hover) {
        // .button--secondary.button--no-background.button--no-padding:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
        &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
          color: $color-black;
          background-color: transparent;
        }
      }

      // .button--secondary.button--no-background.button--no-padding.button--underline-on-hover::after
      &.button--underline-on-hover::after {
        background-color: $color-blue-500;
      }
    }

    // .button--secondary.button--outline
    &.button--outline {
      color: $color-blue-500;
      box-shadow: inset 0 0 0 1px $color-grey-300;

      // .button--secondary.button--outline:active:not(:disabled),
      // .button--secondary.button--outline:active:not(.button--disabled)
      &:active:not(:disabled),
      &:active:not(.button--disabled) {
        box-shadow: inset 0 0 0 1px $color-blue-500;
        background-color: rgba($color-blue-500, 0.04);
      }

      // Necessary to ensure that we have mouse hover
      @media (hover: hover) {
        // .button--secondary.button--outline:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover
        &:not(:disabled):not(.button--disabled):not(.button--no-background):not(.button--no-padding):hover {
          box-shadow: inset 0 0 0 1px $color-blue-500;
          background-color: rgba($color-blue-500, 0.04);
        }
      }

      // .button--secondary.button--outline.focus-visible
      &:global(.focus-visible) {
        outline-color: $color-blue-500;
      }
    }
  }
}
