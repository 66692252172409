@import "src/styles/prepend"; .not-found-page {
  display: flex;
  flex-direction: column;
  min-height: var(--app-height);
  overflow: hidden;
  position: relative;

  // .not-found-page__content
  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(var(--app-height) - 132px);
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      min-height: calc(var(--app-height) - 208px);
    }
  }

  // .not-found-page__description
  &__description {
    margin-bottom: 3 * $layout-unit;
    margin-top: 3 * $layout-unit;
  }

  // .not-found-page__title
  &__title {
    @extend %h3;

    color: $color-primary-500;
    font-size: 20 * $layout-unit;
    font-weight: $font-weight-bold;
    line-height: 20 * $layout-unit;

    @include media-breakpoint-up(md) {
      font-size: 30 * $layout-unit;
      line-height: 30 * $layout-unit;
    }

    @include media-breakpoint-up(md) {
      font-size: 30 * $layout-unit;
      line-height: 30 * $layout-unit;
    }

    @include media-breakpoint-up(lg) {
      font-size: 40 * $layout-unit;
      line-height: 40 * $layout-unit;
    }
  }
}
