/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Families
2.0 Weights
3.0 Sizes
4.0 Leadings
5.0 Trackings
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Families
--------------------------------------------------------------*/

$font-family-primary: var(--font-inter), sans-serif;

/*--------------------------------------------------------------
2.0 Weights
--------------------------------------------------------------*/

$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

/*--------------------------------------------------------------
3.0 Sizes
--------------------------------------------------------------*/

// Body Text
$font-size-extra-small: 12px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-extra-large: 20px;

// Headings
$font-size-heading-1: 50px;
$font-size-heading-2: 40px;
$font-size-heading-3: 30px;
$font-size-heading-4: 28px;
$font-size-heading-5: 24px;
$font-size-heading-6: 20px;

// Headings Mobile
$font-size-heading-1-mobile: 32px;
$font-size-heading-2-mobile: 28px;
$font-size-heading-3-mobile: 24px;
$font-size-heading-4-mobile: 20px;
$font-size-heading-5-mobile: 18px;
$font-size-heading-6-mobile: 16px;

/*--------------------------------------------------------------
4.0 Leadings
--------------------------------------------------------------*/

// Body Text
$font-line-height-extra-small: 16px;
$font-line-height-extra-small-paragraph: 20px;
$font-line-height-small: 22px;
$font-line-height-medium: 24px;
$font-line-height-large: 24px;
$font-line-height-extra-large: 28px;

// Headings
$font-line-height-heading-1: 63px;
$font-line-height-heading-2: 50px;
$font-line-height-heading-3: 38px;
$font-line-height-heading-4: 35px;
$font-line-height-heading-5: 35px;
$font-line-height-heading-6: 27px;

// Headings Mobile
$font-line-height-heading-1-mobile: 40px;
$font-line-height-heading-2-mobile: 35px;
$font-line-height-heading-3-mobile: 30px;
$font-line-height-heading-4-mobile: 25px;
$font-line-height-heading-5-mobile: 23px;
$font-line-height-heading-6-mobile: 20px;

/*--------------------------------------------------------------
5.0 Trackings
--------------------------------------------------------------*/

$font-letter-spacing-tight: -0.04em;
$font-letter-spacing-normal: 0;
$font-letter-spacing-loose: .04em;
