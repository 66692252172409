/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 General
2.0 Elements
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 General
--------------------------------------------------------------*/

$layout-min-width: 375px;
$layout-unit: 4px;

/*--------------------------------------------------------------
2.0 Elements
--------------------------------------------------------------*/
